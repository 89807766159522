import React, { useMemo } from "react";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";

import { Parse } from "parse-hooks";
import dayjs from "dayjs";
import {
  Radio,
  Select,
  Row,
  Col,
  Descriptions,
  Typography,
  notification,
  DatePicker,
  Button,
  Input,
} from "antd";
const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "@opendash/core";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

type NotificationType = "success" | "info" | "warning" | "error";

export default function GreisManualInput({ spsid }: { spsid: string }) {
  const t = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [radio, setRadio] = React.useState(1);
  const [date, setDate] = React.useState(null);
  const [myDate, setMyDate] = React.useState<dayjs.Dayjs | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState<number | null>();
  const [container, setContainer] = React.useState(null);
  const [containerValue, setContainerValue] = React.useState("");
  const [error, setError] = React.useState(0);

  const itemData = [
    { name: "Item 1", date: "2024-01-01" },
    { name: "Item 2", date: "2024-02-01" },
  ];

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const sendEntry = async () => {
    if (radio == 1 && !date) {
      setError(4); //No weight entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_date")
      );
      return;
    } else if (radio == 2 && !myDate) {
      setError(5); //No weight entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_date")
      );
      return;
    } else if (!value || value == 0) {
      setError(1); //No weight entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_weight")
      );
      return;
    } else if (container == null) {
      setError(2); //No container selected
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_container")
      );
      return;
    } else if (container == "other" && containerValue == "") {
      setError(3); //No container size entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_size")
      );
      return;
    }
    setError(0);

    var entryContainerSize = 0;
    if (container == "other") {
      entryContainerSize = Number(containerValue);
    } else {
      entryContainerSize = Number(container);
    }

    let entryDate = new Date();
    if (radio == 1) {
      entryDate = date ?? new Date();
    } else {
      entryDate = myDate ? myDate.toDate() : new Date();
    }

    const entryWeight = value ?? 0;

    let entryFormat = {
      id: "iot.Manually-" + spsid + "-Emptying-Weight",
      parent: [],
      meta: {},
      name: "Status (" + spsid + ")",
      valueTypes: [
        { name: "Emptying Weight", unit: "Kg", type: "Number" },
        { name: "Container Size", unit: "m3", type: "Number" },
      ],
      source: spsid,
      values: [
        { date: entryDate.valueOf(), value: [entryWeight, entryContainerSize] },
      ],
    };

    let entryObject = new Parse.Object("HG_ManualInput");
    entryObject.set("user", Parse.User.current());
    entryObject.set("msg", entryFormat);
    entryObject.set("spsid", spsid);
    entryObject.set("success", false);

    const acl = new Parse.ACL();
    acl.setPublicReadAccess(false);
    acl.setPublicWriteAccess(false);
    acl.setRoleReadAccess("od-admin", true);
    acl.setRoleWriteAccess("od-admin", true);
    entryObject.setACL(acl);

    await entryObject.save();
    openNotificationWithIcon(
      "success",
      t("greis:manualinput.component.success"),
      t("greis:manualinput.component.success_text")
    );
    cancelEntry();
  };

  const cancelEntry = () => {
    setRadio(1);
    setDate(null);
    setMyDate(null);
    setIsOpen(false);
    setValue(null);
    setContainer(null);
    setContainerValue("");
  };

  return (
    <AdminLayout>
      {contextHolder}
      <AdminToolbar
        title={t("greis:manualinput.label")}
        description={t("greis:manualinput.description")}
      />
      <div className="od-page-main">
        {/* Auswahlmodus */}

        <Descriptions bordered column={3}>
          <Descriptions.Item
            label={t("greis:manualinput.component.input_mode")}
            span={3}
          >
            <Radio.Group
              onChange={(e) => setRadio(e.target.value)}
              value={radio}
              style={{ marginTop: "10px" }}
            >
              <Radio.Button value={1}>
                {t("greis:manualinput.component.mode_auto")}
              </Radio.Button>
              <Radio.Button value={2}>
                {t("greis:manualinput.component.mode_manual")}
              </Radio.Button>
            </Radio.Group>
          </Descriptions.Item>

          {/* Auswahl für automatische Leerung */}
          {radio === 1 && (
            <Descriptions.Item
              label={t("greis:manualinput.component.auto_emptying")}
              span={3}
            >
              <Select
                style={{ width: "100%", textAlign: "center" }}
                value={date}
                onChange={(value) => setDate(value)}
                placeholder={t(
                  "greis:manualinput.component.emptying_placeholder"
                )}
              >
                {itemData.map((item) => (
                  <Option key={item.date} value={item.date}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Descriptions.Item>
          )}

          {/* Datumsauswahl */}
          {radio === 2 && (
            <Descriptions.Item
              label={t("greis:manualinput.component.date_select")}
              span={3}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("greis:manualinput.component.enter_date")}
                value={myDate ? dayjs(myDate) : null}
                onChange={(date) => setMyDate(date as dayjs.Dayjs)}
                open={isOpen}
                format="DD.MM.YYYY"
                onOpenChange={(open) => setIsOpen(open)}
              />
            </Descriptions.Item>
          )}

          {/* Eingabe für Gewicht */}

          <Descriptions.Item
            label={t("greis:manualinput.component.enter_fill")}
            span={3}
          >
            <Input
              type="number"
              value={value ?? 0}
              onChange={(e) => setValue(parseInt(e.target.value))}
              maxLength={50}
              minLength={1}
              style={{ textAlign: "center" }}
              placeholder={t("greis:manualinput.component.weight_placeholder")}
            />
          </Descriptions.Item>

          {/* Auswahl für Containergewicht */}

          <Descriptions.Item
            label={t("greis:manualinput.component.container_size")}
            span={3}
          >
            <Radio.Group
              value={container}
              onChange={(e) => setContainer(e.target.value)}
              style={{ width: "100%" }}
            >
              <Radio value="26">26m³</Radio>
              <Radio value="32">32m³</Radio>
              <Radio value="38">38m³</Radio>
              <Radio value="other">
                {t("greis:manualinput.component.other_size")}
              </Radio>
            </Radio.Group>
            {container === "other" && (
              <Input
                type="number"
                value={containerValue}
                onChange={(e) => setContainerValue(e.target.value)}
                maxLength={50}
                minLength={1}
                style={{ textAlign: "center", marginTop: "10px" }}
                placeholder={t(
                  "greis:manualinput.component.enter_container_size"
                )}
              />
            )}
          </Descriptions.Item>
          {/* Senden und Abbrechen Buttons */}
          <Descriptions.Item style={{ textAlign: "right" }}>
            <Button type="primary" onClick={sendEntry}>
              {t("greis:manualinput.component.button_send")}
            </Button>{" "}
            &nbsp;
            <Button type="default" onClick={cancelEntry}>
              {t("greis:manualinput.component.button_cancel")}
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </AdminLayout>
  );
}
