import React, { useMemo } from "react";
import styled from "styled-components";

import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";
import { Parse } from "parse-hooks";
import { Icon } from "@opendash/icons";
import dayjs from "dayjs";
import {
  Alert,
  Button,
  Descriptions,
  Badge,
  Modal,
  Input,
  Statistic,
  Select,
  InputNumber,
  notification,
  Col,
  Popconfirm,
  Row,
} from "antd";

const { Option } = Select;

import { useTranslation, copy } from "@opendash/core";

const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  > button {
    margin-left: 8px;
  }
`;

export default function GreisOperatingHoursSelect({
  spsid,
  admin,
  config,
  user,
  name,
}) {
  let { result, loading, error } = config;

  const [parseObj, setparseObj] = React.useState(result);
  const t = useTranslation();

  const [data, setData] = React.useState([]);
  const [userData, setUserData] = React.useState();
  const [showDataNow, setShowDataNow] = React.useState(0);
  const [showDataNowDate, setShowDataDate] = React.useState(0);
  const [showData24, setShowData24] = React.useState(0);
  const [showData60, setShowData60] = React.useState(0);
  const [showData724, setShowData724] = React.useState(0);
  const [showData770, setShowData770] = React.useState(0);
  const [showDataIndex, setShowDataIndex] = React.useState(0);

  const [opConfig, setOPConfig] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [deviationSet, setDeviationSet] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [actionType, setActionType] = React.useState();
  const [difference, setDifference] = React.useState(0);
  const [userMail, setUserMail] = React.useState("");

  const [saveactionType, setSaveActionType] = React.useState();
  const [savedifference, setSaveDifference] = React.useState(0);
  const [saveuserMail, setSaveUserMail] = React.useState("");

  const [configExist, setConfigExist] = React.useState(false);

  if (!spsid) {
    return (
      <Alert
        type="info"
        message={t("greis:operatinghours.ui.select_location_first")}
      />
    );
  }

  let translateType = [
    t("greis:operatinghours.ui.daylie"),
    t("greis:operatinghours.ui.weekly"),
  ];

  React.useEffect(() => {
    if (result && Object.keys(result.get("config")).length != 0) {
      setOPConfig(copy(result.get("config")));
      setConfigExist(true);
      setActive(copy(result.get("status")));
      setDifference(copy(result.get("config").diff));
      setUserMail(copy(result.get("config").mail));
      setActionType(copy(result.get("config").type));
    } else {
      setOPConfig({});
      setConfigExist(false);
      setActive(false);
    }
  }, [result]);

  let baseURL = "https://hg.openinc.de";
  let itemURL = "/api/data/items";
  let historyURL = "/api/data/historical";

  React.useEffect(() => {
    if (user != userData) {
      setUserData(user);
    }
  }, [user]);

  React.useEffect(() => {
    if (userData) {
      let isCancelled = false;
      const getData = async (spsid) => {
        const response = await fetch(
          baseURL + itemURL + "/" + user.getUsername() + "/" + spsid,
          {
            method: "GET",
            headers: {
              "OD-Session": user.getSessionToken(),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Bad response from backend");
        }

        const body = await response.json();
        if (!isCancelled) {
          setData(body);
        }
      };

      getData(spsid).catch(console.error);

      return () => {
        isCancelled = true;
      };
    }
  }, [userData]);

  React.useEffect(() => {
    if (data.length > 0) {
      data.forEach((dataItem) => {
        let indexOfItem = 0;
        dataItem["valueTypes"].forEach((valueType) => {
          if (
            valueType["name"].includes("Betriebsstunden") ||
            valueType["name"].includes("Operating") ||
            valueType["name"].includes("Operational")
          ) {
            //Operating Hours
            setShowDataIndex(indexOfItem);
            setShowDataNow(dataItem.values[0].value[indexOfItem]);
            setShowDataDate(dataItem.values[0].date);
            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("day").subtract(24, "h"),
              dayjs(Date.now()).startOf("day"),
              spsid,
              showDataIndex,
              1
            ).catch(console.error);
            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("day").subtract(60, "d"),
              dayjs(Date.now()).startOf("day"),
              spsid,
              showDataIndex,
              2
            ).catch(console.error);
            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("week").add(1, "d").subtract(7, "d"),
              dayjs(Date.now()).startOf("week").add(1, "d"),
              spsid,
              showDataIndex,
              3
            ).catch(console.error);

            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("week").add(1, "d").subtract(77, "d"),
              dayjs(Date.now()).startOf("week").add(1, "d").subtract(7, "d"),
              spsid,
              showDataIndex,
              4
            ).catch(console.error);

            indexOfItem++;
          }
        });
      });
    }
  }, [data]);

  const getHistoryData = async (
    data,
    id,
    start,
    end,
    spsid,
    dimension,
    type
  ) => {
    const response = await fetch(
      baseURL + historyURL + spsid + "/" + id + "/" + start + "/" + end /*+
        "?mode=minmax&dimension=" +
        dimension +
        "&maxValues=20"*/,
      {
        method: "GET",
        headers: {
          "OD-Session": user.getSessionToken(),
        },
      }
    );

    if (!response.ok) {
      throw new Error("Bad response from backend");
    }

    const body = await response.json();

    if (type == 1) {
      //24 Stunden
      console.log(body.values);
      let value24 =
        body.values.length > 0
          ? body.values[body.values.length - 1].value[showDataIndex] -
            body.values[0].value[showDataIndex]
          : 0;
      setShowData24(value24);
    } else if (type == 2) {
      //60 Tage
      let value60 =
        body.values.length > 0
          ? Math.floor(
              (body.values[body.values.length - 1].value[showDataIndex] -
                body.values[0].value[showDataIndex]) /
                60
            )
          : 0;
      setShowData60(value60);
    } else if (type == 3) {
      // 7 Tage
      let value724 =
        body.values.length > 0
          ? body.values[body.values.length - 1].value[showDataIndex] -
            body.values[0].value[showDataIndex]
          : 0;
      setShowData724(value724);
    } else if (type == 4) {
      // 70 Tage
      let value770 =
        body.values.length > 0
          ? Math.floor(
              (body.values[body.values.length - 1].value[showDataIndex] -
                body.values[0].value[showDataIndex]) /
                10
            )
          : 0;
      setShowData770(value770);
    }
  };

  const openNotificationWithIcon = (type: "info", title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const showModal = () => {
    try {
      setSaveActionType(copy(actionType));
      setSaveDifference(copy(difference));
      setSaveUserMail(copy(userMail));
    } catch (e) {
      console.log("First Start....", e);
    }

    if (difference > 0) {
      if (actionType == "1") {
        console.log("Action Type 1");
        let upperCorner = showData60 + showData60 * (difference / 100);
        let lowerCorner = showData60 - showData60 * (difference / 100);
        if (showData24 > upperCorner || showData24 < lowerCorner) {
          setDeviationSet(true);
        }
      } else if (actionType == "2") {
        console.log("Action Type 2");
        let upperCorner = showData770 + showData770 * (difference / 100);
        let lowerCorner = showData770 - showData770 * (difference / 100);
        if (showData724 > upperCorner || showData724 < lowerCorner) {
          setDeviationSet(true);
        }
      }
    }

    setIsModalOpen(true);
  };

  const delConfig = () => {
    try {
      parseObj.destroy().then(
        (e) => {
          setparseObj(null);
          setOPConfig({});
          setConfigExist(false);
          setActive(false);
          setActionType(null);
          setDifference(0);
          setUserMail("");
          setSaveActionType(null);
          setSaveDifference(0);
          setSaveUserMail("");
          console.log(parseObj);
        },
        (error) => {
          console.log("Parse Error deleting Object...");
        }
      );
      openNotificationWithIcon(
        "info",
        t("greis:operatinghours.ui.config_deleted"),
        t("greis:operatinghours.ui.config_deleted_text")
      );
    } catch (e) {
      console.log("Delete Error....", e);
    }
  };

  const setActiveStatus = (e) => {
    console.log(result);
    parseObj.set("status", e);
    parseObj.save().then((f) => {
      setOPConfig(copy(parseObj.get("config")));
      setConfigExist(true);
      setActive(e);
      setActionType(copy(actionType));
      setDifference(copy(difference));
      setUserMail(copy(userMail));
      setparseObj(f);
      if (e) {
        openNotificationWithIcon(
          "info",
          t("greis:operatinghours.ui.monitoring_active"),
          t("greis:operatinghours.ui.monitoring_active_text")
        );
      } else {
        openNotificationWithIcon(
          "info",
          t("greis:operatinghours.ui.monitoring_not_active"),
          t("greis:operatinghours.ui.monitoring_not_active_text")
        );
      }
    });
  };

  const saveConfig = () => {
    try {
      parseObj.set("spsid", spsid);
      parseObj.set("status", active);
      let configObject = {
        type: saveactionType,
        mail: saveuserMail,
        diff: savedifference,
      };
      parseObj.set("config", configObject);
      parseObj.save().then((e) => {
        setOPConfig(copy(parseObj.get("config")));
        setConfigExist(true);
        setActionType(copy(saveactionType));
        setDifference(copy(savedifference));
        setUserMail(copy(saveuserMail));
        openNotificationWithIcon(
          "success",
          t("greis:operatinghours.ui.config_saved"),
          t("greis:operatinghours.ui.config_saved_text")
        );
        setparseObj(e);
      });
    } catch (e) {
      setActive(true);
      const OService = Parse.Object.extend("HG_Runtime");
      result = new OService();
      result.set("spsid", spsid);
      result.set("status", true);
      let configObject = {
        type: saveactionType,
        mail: saveuserMail,
        diff: savedifference,
      };
      result.set("config", configObject);

      var acl = new Parse.ACL(user);
      acl.setReadAccess(user, true);
      acl.setWriteAccess(user, true);
      acl.setReadAccess("role:Admin", true);
      acl.setWriteAccess("role:Admin", true);
      acl.setPublicWriteAccess(false);
      acl.setPublicReadAccess(false);

      result.setACL(acl);

      result.save().then((e) => {
        setOPConfig(copy(result.get("config")));
        setConfigExist(true);
        setActionType(copy(saveactionType));
        setDifference(copy(savedifference));
        setUserMail(copy(saveuserMail));
        openNotificationWithIcon(
          "success",
          t("greis:operatinghours.ui.config_saved"),
          t("greis:operatinghours.ui.config_saved_text")
        );
        setparseObj(e);
      });
    }
  };

  const changeNumber = (number, actionTyper) => {
    if (number > 0) {
      if (actionTyper == "1") {
        let upperCorner = showData60 + showData60 * (number / 100);
        let lowerCorner = showData60 - showData60 * (number / 100);

        console.log(
          "Upper: " +
            upperCorner +
            " - Lower: " +
            lowerCorner +
            " | Value: " +
            showData24
        );

        if (showData24 > upperCorner || showData24 < lowerCorner) {
          setDeviationSet(true);
        } else {
          setDeviationSet(false);
        }
      } else if (actionTyper == "2") {
        let upperCorner = showData770 + showData770 * (number / 100);
        let lowerCorner = showData770 - showData770 * (number / 100);
        if (showData724 > upperCorner || showData724 < lowerCorner) {
          setDeviationSet(true);
        } else {
          setDeviationSet(false);
        }
      }
    }
  };

  const handleOk = () => {
    if (saveactionType && saveactionType > 0 && saveuserMail.length > 4) {
      //Save Values in Parse
      saveConfig();
      setIsModalOpen(false);
    } else {
      setHasError(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let getAction = (admin, active, configExist) => {
    let actions = [];
    if (admin && !active) {
      actions.push(
        <Button
          disabled={!configExist}
          type="primary"
          icon={<Icon icon="fa:check" />}
          children={t("greis:operatinghours.ui.button_activate")}
          onClick={() => {
            setActiveStatus(true);
          }}
        />
      );
    } else if (admin && active) {
      actions.push(
        <Button
          danger
          type="dashed"
          icon={<Icon icon="fa:pause" />}
          children={t("greis:operatinghours.ui.button_deactivate")}
          onClick={() => {
            setActiveStatus(false);
          }}
        />
      );
    }

    if (admin) {
      actions.push(
        <Button
          icon={<Icon icon="fa:pen" />}
          onClick={showModal}
          children={t("greis:operatinghours.ui.button_edit")}
        />
      );
    }

    if (admin && configExist) {
      actions.push(
        <Popconfirm
          title={t("greis:operatinghours.ui.button_delete_text")}
          onConfirm={delConfig}
          okText={t("greis:operatinghours.ui.button_delete_yes")}
          cancelText={t("greis:operatinghours.ui.button_delete_no")}
        >
          <Button
            danger
            icon={<Icon icon="fa:trash" />}
            children={t("greis:operatinghours.ui.button_delete")}
          />
        </Popconfirm>
      );
    }

    return actions;
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("greis:operatinghours.label")}
        description={t("greis:operatinghours.description")}
        actions={getAction(admin, active, configExist)}
      />
      <div className="od-page-main">
        <Modal
          title={t("greis:operatinghours.ui.modal_title")}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("greis:operatinghours.ui.modal_cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              {t("greis:operatinghours.ui.modal_save")}
            </Button>,
          ]}
        >
          <p>
            <Select
              placeholder={t("greis:operatinghours.ui.modal_placeholder")}
              style={{ width: "100%" }}
              value={saveactionType}
              onChange={(e) => {
                setSaveActionType(e);
                setHasError(false);
                changeNumber(savedifference, e);
              }}
            >
              <Option value="1">
                {t("greis:operatinghours.ui.modal_daylie")}
              </Option>
              <Option value="2">
                {t("greis:operatinghours.ui.modal_weekly")}
              </Option>
            </Select>
          </p>
          <p>
            <InputNumber
              style={{ width: "100%" }}
              placeholder={t("greis:operatinghours.ui.modal_diff")}
              min={0}
              max={1000}
              value={savedifference}
              onChange={(e) => {
                changeNumber(e, saveactionType);
                setSaveDifference(e);
              }}
              formatter={(value) => `${value}%`}
            />
          </p>
          <p>
            <Input
              placeholder={t("greis:operatinghours.ui.modal_mail")}
              value={saveuserMail}
              onChange={(e) => {
                setSaveUserMail(e.target.value);
                setHasError(false);
              }}
              prefix={
                <Icon icon="fa:envelope" style={{ marginRight: "5px" }} />
              }
            />
          </p>

          <p>
            {saveactionType == 1 && (
              <Alert
                message={t("greis:operatinghours.ui.modal_actionday_text")}
                type="info"
              />
            )}
            {saveactionType == 2 && (
              <Alert
                message={t("greis:operatinghours.ui.modal_actionweek_text")}
                type="info"
              />
            )}
            {deviationSet && (
              <div>
                <br />
                <Alert
                  message={t("greis:operatinghours.ui.modal_hint")}
                  type="warning"
                />
              </div>
            )}
          </p>

          <p>
            {hasError && (
              <Alert
                message={t("greis:operatinghours.ui.modal_error")}
                type="error"
              />
            )}
          </p>
        </Modal>
        <Row>
          <Col md={24} xl={24}>
            {!active && configExist && (
              <Alert
                message={t("greis:operatinghours.ui.noti_deactive")}
                type="error"
              />
            )}
          </Col>
        </Row>
        <Descriptions bordered column={3}>
          <Descriptions.Item
            label={t("greis:operatinghours.ui.table_source")}
            span={3}
          >
            <b>
              {name} | {spsid}{" "}
            </b>
          </Descriptions.Item>
          <Descriptions.Item
            label={t("greis:operatinghours.ui.table_ophours")}
            span={3}
          >
            {showDataNow} {t("greis:operatinghours.ui.table_details_hours")}
          </Descriptions.Item>
          <Descriptions.Item
            label={t("greis:operatinghours.ui.table_date")}
            span={3}
          >
            {dayjs(showDataNowDate).year() >= 2000
              ? `${dayjs(showDataNowDate).format("DD.MM.YYYY")} | ${dayjs(
                  showDataNowDate
                ).format("HH:mm:ss")}`
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item
            label={t("greis:operatinghours.ui.table_status")}
            span={3}
          >
            {active && <Badge status="success" text="Status" />}
            {!active && <Badge status="error" text="Status" />}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              t("greis:operatinghours.ui.table_details") +
              " (" +
              t("greis:operatinghours.ui.table_details_hours") +
              ")"
            }
            span={3}
          >
            <Row gutter={14}>
              <Col span={10} style={{ borderRight: "1px solid lightgray" }}>
                <Statistic
                  title={t("greis:operatinghours.ui.table_details_yesterday")}
                  value={showData24}
                  valueStyle={{
                    color: showData24 >= showData60 ? "#3f8600" : "#cf1322",
                  }}
                  prefix={
                    showData24 >= showData60 ? (
                      <Icon icon="fa:arrow-up" />
                    ) : (
                      <Icon icon="fa:arrow-down" />
                    )
                  }
                  style={{ textAlign: "center" }}
                />
              </Col>
              <Col span={10}>
                <Statistic
                  title={t("greis:operatinghours.ui.table_details_lastweek")}
                  value={showData724}
                  valueStyle={{
                    color: showData724 >= showData770 ? "#3f8600" : "#cf1322",
                  }}
                  prefix={
                    showData724 >= showData770 ? (
                      <Icon icon="fa:arrow-up" />
                    ) : (
                      <Icon icon="fa:arrow-down" />
                    )
                  }
                  style={{ textAlign: "center" }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={14}>
              <Col span={10} style={{ borderRight: "1px solid lightgray" }}>
                <Statistic
                  title={t("greis:operatinghours.ui.table_details_60d")}
                  value={showData60}
                  style={{ textAlign: "center" }}
                  prefix={<Icon icon="fa:arrow-right" />}
                />
              </Col>
              <Col span={10}>
                <Statistic
                  title={t("greis:operatinghours.ui.table_details_last10week")}
                  value={showData770}
                  style={{ textAlign: "center" }}
                  prefix={<Icon icon="fa:arrow-right" />}
                />
              </Col>
            </Row>
          </Descriptions.Item>
          {configExist && (
            <Descriptions.Item
              label={t("greis:operatinghours.ui.table_config")}
              span={3}
            >
              {t("greis:operatinghours.ui.table_config_periode")}:{" "}
              {translateType[actionType - 1]}
              <br />
              {t("greis:operatinghours.ui.table_config_diff")}: {difference}%
              <br />
              {t("greis:operatinghours.ui.table_config_mail")}: {userMail}
            </Descriptions.Item>
          )}
          {!configExist && (
            <Descriptions.Item
              label={t("greis:operatinghours.ui.table_config")}
              span={3}
            >
              <b>{t("greis:operatinghours.ui.table_no_config")}</b>
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    </AdminLayout>
  );
}
